import React from "react";
// import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { differenceInDays, formatDistance, format } from "date-fns";

import { getBlogUrl, toPlainText } from "../../lib/helpers";
// import PortableText from "./portableText";

function BlogPostPreview(props) {
  const truncate = (str) => {
    return str.length > 200 ? str.substring(0, 200) + "..." : str;
  };

  return (
    <AniLink
      fade
      to={getBlogUrl(props.slug.current)}
      className="group mb-10 block border-b border-gray-200 pb-12 font-normal text-gray-700 no-underline hover:text-gray-700"
    >
      {/* {props.featuredImage && props.featuredImage.asset && (
          <div className="overflow-hidden mb-6">
            <GatsbyImage
              image={props.featuredImage.asset.gatsbyImageData}
              // className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
            />
          </div>
        )} */}
      <div className="mb-5 flex flex-wrap items-center font-body text-sm font-medium uppercase tracking-wide text-primary-900/40 md:flex-nowrap">
        {/* {props.categories && (
            <ul className="flex flex-wrap md:flex-nowrap">
              {props.categories.map((category, i) => (
                <li
                  className="font-body text-sm font-bold uppercase tracking-wide text-gray-300"
                  key={i}
                >
                  {i > 0 ? <>, {category.title}</> : category.title}
                </li>
              ))}
            </ul>
          )} */}
        {/* {props.categories && props.publishedAt && (
            <div className="font-body text-sm text-gray-300 font-bold tracking-wide uppercase mx-1">
              ·
            </div>
          )} */}
        {props.publishedAt && (
          <div>
            {differenceInDays(new Date(props.publishedAt), new Date()) > 3
              ? formatDistance(new Date(props.publishedAt), new Date())
              : format(new Date(props.publishedAt), "MMMM d, yyyy")}
          </div>
        )}
      </div>
      <div className="mb-2 font-heading text-3xl font-bold text-primary-900">
        {props.title}
      </div>
      {props._rawBody && <p>{truncate(toPlainText(props._rawBody))}</p>}
      <div className="relative inline-flex font-bold text-primary-500 no-underline">
        <span>Read Article</span>
        <span className="absolute -bottom-2 left-auto right-0 h-0.5 w-0 bg-primary-500 transition-all duration-500 ease-in-out group-hover:left-0 group-hover:right-auto group-hover:w-full"></span>
      </div>
    </AniLink>
  );
}

export default BlogPostPreview;
