import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";
import styled from "@emotion/styled";
import tw from "twin.macro";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";
import PortableText from "./portableText";
// import Newsletter from "../Form/Newsletter";
import BlogPostPreview from "./BlogPostPreview";
import CallToAction from "../Repeating/CTA";
import ButtonSolid from "../Button/ButtonSolid";

const StyledContent = styled.div`
  p,
  span,
  li {
    ${tw`md:text-xl`}
  }
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  strong {
    ${tw`font-bold`}
  }
  em {
    ${tw`italic`}
  }
`;

function BlogPost(props) {
  const {
    siteMetadata,
    // next,
    // prev,
    slug,
    _rawBody,
    author,
    categories,
    title,
    // featuredImage,
    publishedAt,
  } = props;

  const data = useStaticQuery(graphql`
    {
      posts: allSanityPost(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `);

  const currentPostCategory =
    categories.length > 0 &&
    categories.slice(0, 1).map((category) => category.title);

  let postNodes = [];

  categories.length > 0
    ? (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
            .filter((items) =>
              items.categories.find(
                (item) => item.title === currentPostCategory
              )
            )
        : [])
    : (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : []);

  return (
    <>
      <section className="bg-primary-900 py-20">
        <div className="container">
          <div className="mx-auto max-w-[720px]">
            <div className="mb-10 flex items-center space-x-3.5">
              <div className="inline-block rounded-sm border-2 border-primary-500 px-2 text-sm font-medium uppercase text-primary-500">
                Blog
              </div>
              {publishedAt && (
                <div className="text-sm font-normal text-white">
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? formatDistance(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), "MMMM d, yyyy")}
                </div>
              )}
            </div>
            <header className="mb-8 md:mb-10">
              <h1 className="text-white">{title}</h1>
            </header>

            <div className="flex items-center space-x-4">
              {author && author.headshot && (
                <div>
                  <GatsbyImage
                    image={author.headshot.asset.gatsbyImageData}
                    className="z-0 h-12 w-12 rounded-full"
                  />
                </div>
              )}

              <div>
                <div className="font-bold text-secondary-50">
                  {author && (
                    <div>
                      <AniLink
                        fade
                        to={`/${author.slug.current}/`}
                        className="text-white no-underline hover:text-secondary-500"
                      >
                        {author.name}
                      </AniLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <article className="mb-20 pt-12 md:mb-32 md:pt-20">
        <div className="container">
          <div className="mx-auto max-w-[720px]">
            {/* <div className="mb-8 flex items-end justify-between md:mb-10">
            <div className="hidden items-center space-x-7 md:flex">
              <FacebookShareButton
                url={`${siteMetadata.siteUrl}/${slug.current}/`}
                quote={title}
              >
                <i className="fab fa-facebook-f text-xl text-primary-800 bg-primary-50 rounded-full transition-colors hover:bg-primary-800 duration-300 ease-linear hover:text-white w-10 h-10 flex justify-center items-center"></i>
              </FacebookShareButton>

              <LinkedinShareButton
                url={`${siteMetadata.siteUrl}/${slug.current}/`}
                title={title}
              >
                <i className="fab fa-linkedin-in text-xl text-primary-800 bg-primary-50 rounded-full transition-colors hover:bg-primary-800 duration-300 ease-linear hover:text-white w-10 h-10 flex justify-center items-center"></i>
              </LinkedinShareButton>

              <TwitterShareButton
                url={`${siteMetadata.siteUrl}/${slug.current}/`}
                title={title}
              >
                <i className="fab fa-twitter text-xl text-primary-800 bg-primary-50 rounded-full transition-colors hover:bg-primary-800 duration-300 ease-linear hover:text-white w-10 h-10 flex justify-center items-center"></i>
              </TwitterShareButton>
            </div>
          </div> */}

            {/* {featuredImage && featuredImage.asset && (
            <div className="mb-12 md:mb-20">
              <GatsbyImage image={props.featuredImage.asset.gatsbyImageData} />
            </div>
          )} */}

            <StyledContent>
              {_rawBody && <PortableText blocks={_rawBody} />}
            </StyledContent>

            <div className="mt-16 md:mt-20">
              <h2>About Dark Horse CPAs</h2>
              <p className="md:text-xl">
                Dark Horse CPAs provides integrated tax, accounting, and CFO
                services to small businesses and individuals across the U.S. The
                firm was founded to save small businesses (and their owners)
                from subpar accounting and tax services and subpar client
                experiences. These small businesses are Dark Horses among their
                larger and more well-known competition. Being a Dark Horse CPA
                means advocating for small businesses by bringing them the tax
                strategies and accounting insights previously reserved for big
                business. <AniLink href="/get-a-quote/">Get a quote</AniLink>{" "}
                today.
              </p>
            </div>

            <div className="mb-24 mt-14 md:mb-36 md:mt-16">
              <div className="mb-2.5 text-center text-sm font-medium uppercase text-primary-800">
                Share
              </div>
              <div className="flex items-center justify-center space-x-6">
                <FacebookShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  quote={title}
                  className="no-underline"
                >
                  <i className="fab fa-facebook-f !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                </FacebookShareButton>

                <LinkedinShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-linkedin-in !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                </LinkedinShareButton>

                <TwitterShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-twitter !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                </TwitterShareButton>
              </div>
            </div>

            {/* <div className="mt-16 flex justify-between">
              {prev ? (
                <AniLink
                  fade
                  to={`/${prev.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-bold text-primary-500 hover:text-primary-500"
                >
                  <i className="far fa-arrow-left relative right-0 text-xl transition-all duration-300 ease-linear group-hover:right-1.5"></i>{" "}
                  <span>Previous</span>
                </AniLink>
              ) : (
                <div></div>
              )}
              {next ? (
                <AniLink
                  fade
                  to={`/${next.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-bold text-primary-500 hover:text-primary-500"
                >
                  <span>Next</span>
                  <i className="far fa-arrow-right relative left-0 text-xl transition-all duration-300 ease-linear group-hover:left-1.5"></i>
                </AniLink>
              ) : (
                <div></div>
              )}
            </div> */}
          </div>
        </div>
      </article>

      <section className="pb-20 md:pb-32">
        <div className="container">
          <div className="mb-8 flex items-center justify-between md:mb-10">
            <h2 className="mb-0">Recent Articles</h2>
            <ButtonSolid
              altStyle={3}
              href="/small-business-accounting-blog/"
              text="Read All Articles"
              className="hidden md:inline-flex"
            />
          </div>

          {postNodes && postNodes.length > 0 && (
            <div className="mb-10 md:mb-0">
              {postNodes.slice(0, 2).map((node) => (
                <div key={node.id}>
                  <BlogPostPreview {...node} isInList />
                </div>
              ))}
            </div>
          )}

          <ButtonSolid
            altStyle={3}
            href="/small-business-accounting-blog/"
            text="Read All Articles"
            className="md:hidden"
          />
        </div>
      </section>

      <CallToAction />
    </>
  );
}

export default BlogPost;
